(function( $ ) {

    $.fn.catalogMenu = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var openButton = jqContainer.find('a.menu-open');
            openButton.click(function(e) {
                e.preventDefault();
                if (jqContainer.hasClass('open')) {
                    jqContainer.removeClass('open');
                } else {
                    jqContainer.addClass('open');
                }
            });
        });

        return jqAllAffectedContainers;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.catalog-menu').catalogMenu();
});
