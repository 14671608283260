(function( $ ) {

    $.fn.mobileCatalogMenu = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var subMenuContainer = jqContainer.find('.sub-menu')[0];
            if (!subMenuContainer || subMenuContainer === undefined) {
                console.error('mobileCatalogMenu: no "sub-menu" in ', this);
            }
            var backButton = jqContainer.find('.back')[0];
            if (!backButton || backButton === undefined) {
                console.error('mobileCatalogMenu: no "back" in ', this);
            }
            var openLink = jqContainer.find('>a')[0];
            if (!openLink || openLink === undefined) {
                console.error('mobileCatalogMenu: no "a" in ', this);
            }
            console.log()
            $(openLink).click(function(e) {
                e.preventDefault();
                $(subMenuContainer).addClass('open');
            });
            $(backButton).click(function(e) {
                e.preventDefault();
                $(subMenuContainer).removeClass('open');
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.mobile-main-menu-nav .has-sub-menu').mobileCatalogMenu();
});
