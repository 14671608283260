(function( $ ) {

    $.fn.customScroll = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var openLink = jqContainer.find('>a');
            var closeLink = jqContainer.find('.close>a');
            var bar = jqContainer.find('.mobile-search-bars');
            openLink.click(function(e){
                e.preventDefault();
                bar.addClass('open');
            });
            closeLink.click(function(e){
                e.preventDefault();
                bar.removeClass('open');
            });

        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.mobile-search').customScroll();
});
